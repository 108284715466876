$primary-color: #3498db;

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100vh; // Full viewport height

  .video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    // background-image: url('/background.jpg'); 
    // background-size: cover;
    // background-position: center;
  }

  .logo-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .logo {
    height: 40vmin;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.1;
    }
  }
}
